<script>
import Navbar from "@/view/front/components/navbar";
import Switcher from "@/view/front/components/switcher";
import Footer from "@/view/front/components/footer";

/**
 * Page-Advanced Search component
 */
export default {
    data() {
        return {}
    },
    components: {
        Navbar,
        Switcher,
        Footer,
    }
}
</script>

<template>
<div>
    <Navbar />

    <!-- Hero Start -->
    <section class="section_ch_p bg-light d-table w-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="page-next-level">
                        <section>
        <div class="row page-sectionch">
            <div class="container pagech ">
                
                <h1 class="simple-heading">Advance Search</h1>
                <hr class="hrch">
               
               
                <form>
                        <div class="col-sm-6 width_ch form-group">
                            <input type="text" class="form-control input_ch_h" placeholder="Advance Search">
                        </div>

                        <div class="col-sm-6 width_ch form-group">
                            <select class="selectch form-control input_ch_h">
                                <option selected="" disabled="">Job Type</option>
                                <option>All</option>
                                                    <option>Permanent</option>
                                                    <option>Full Time</option>
                                                    <option>Part-Time</option>
                                                    <option>Contract</option>
                                                    <option>Internship</option>
                                                </select>
                                            </div>
                                            
                                            <div class="col-sm-6 width_ch form-group">
                                                <select class="selectch form-control input_ch_h">
                                                    <option selected="" disabled="">Salary Range</option>
                                                    <option value="20000">&gt; Upto 20,000</option>
                                                    <option value="20001-40000">20,001 - 40,000</option>
                                                    <option value="40001-60000">40,001 - 60,000</option>
                                                    <option value="60001-80000">60,001 - 80,000</option>
                                                    <option value="80001-100000">80,001 - 100,000</option>
                                                    <option value="100001-125000">100,001 - 125,000</option>
                                                    <option value="125001-150000">125,001 - 150,000</option>
                                                    <option value="150001-175000">150,001 - 175,000</option>
                                                    <option value="175001-225000">175,001 - 225,000</option>
                                                    <option value="225001-275000">225,001 - 275,000</option>
                                                    <option value="275001-300000">275,001 - 300,000</option>
                                                    <option value="300000-350000">300,000 - 350,000</option>
                                                    <option value="More than 350000"> 350,000 + </option>
                                                </select>
                                            </div>
                                            
                                            <div class="col-sm-6 width_ch form-group">
                                                <select class="selectch form-control input_ch_h">
                                                    <option selected="" disabled="">Select City</option>
                                                    <option>Lahore</option>
                                                    <option>Karachi</option>
                                                    <option>Faisalabad</option>
                                                    <option>Sialkot</option>
                                                </select>
                                            </div>
                                            
                                            <div class="col-sm-6 width_ch form-group">
                                                <select class="selectch form-control input_ch_h">
                                                    <option selected="" disabled="">Select Skills</option>
                                                    <option>All</option>
                                                    <option>Accounting</option>
                                                    <option>Banking</option>
                                                    <option>Coding</option>
                                                    <option>Designing</option>
                                                    <option>Development</option>
                                                </select>
                                            </div>
                                            
                                            <div class="col-sm-6 width_ch form-group">
                                                <select class="selectch form-control input_ch_h">
                                                    <option selected="" disabled="">Select Degree Level</option>
                                                    <option>All</option>
                                                    <option>Undergraduate</option>
                                                    <option>Graduate</option>
                                                    <option>Post Graduate</option>
                                                    <option>Researcher</option>
                                                    <option>Doctoral</option>
                                                    <option>Post Doctoral</option>
                                                </select>
                                            </div>

                                            <div class="col-sm-12 form-group">
                                                <button type="submit" class="btn btn-defaultch"><i class="fa fa-search icon_s_ch"></i>&nbsp; Search</button>
                                                <a href="#" class="btn btn-danger ch_cansel_btn">Cancel</a>
                                            </div>

                                        </form>

                                    
                                    
                                </div>
                            </div>

                        </section>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    
    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
        <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
</div>
</template>
